import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ToggleColorMode from './ToggleColorMode';
import { ReactComponent as SanskaarLogo } from '../../assets/NewLogoAgainPNG.svg';
import { Link } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for More dropdown menu
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigation = (sectionId) => {
    console.log(location.pathname)
    if (location.pathname !== '/') {
      navigate('/', { state: { sectionId } });
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      scrollToSection(location.state.sectionId);
    }
  }, [location]);

  // Handle More menu open/close
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'hsla(220, 60%, 99%, 0.6)',
            boxShadow:
              '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)',
              boxShadow:
                '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
            }),
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <SanskaarLogo
              width={'3rem'}
              height={'3rem'}
              style={{ fill: 'var(--logo-fill-color)', stroke: 'var(--logo-stroke-color)' }}
              onClick={() => navigate('/')}
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => handleNavigation('services')}
              >
                Services
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => handleNavigation('testimonials')}
              >
                Testimonials
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => handleNavigation('highlights')}
              >
                Highlights
              </Button>
              
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigate('/blogs')}
                sx={{ minWidth: 0 }}
              >
                Blogs
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => handleNavigation('AppointmentContainer')}
                sx={{ color: 'hsl(210, 98%, 42%)' }}
              >
                <strong>Appointments</strong>
              </Button>

              {/* "More" dropdown menu */}
              <Button
                aria-controls="more-menu"
                aria-haspopup="true"
                onClick={handleMoreClick}
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
              >
                More&nbsp;<ExpandMoreOutlinedIcon />
              </Button>
              <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMoreClose}
              >
                <MenuItem onClick={() => { navigate('/about-us'); handleMoreClose(); }}>
                  About Us
                </MenuItem>
                <MenuItem onClick={() => { navigate('/contact-us'); handleMoreClose(); }}>
                  Contact Us
                </MenuItem>
                <MenuItem onClick={() => { handleNavigation('faq'); handleMoreClose(); }}>
                  FAQs
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {/* Right side: Color mode toggle and link */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            
            <Button color="primary" variant="text" size="small" sx={{ color: '', textTransform: 'none' }}>
              <Link href="https://calendly.com/sitinovations-jvod/60min" underline="none" sx={{ color: 'inherit' }}>
                <b>Get Your Growth</b>
              </Link>
            </Button>
          </Box>

          {/* Mobile menu */}
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => handleNavigation('services')}>Services</MenuItem>
                <MenuItem onClick={() => handleNavigation('testimonials')}>Testimonials</MenuItem>
                <MenuItem onClick={() => handleNavigation('highlights')}>Highlights</MenuItem>
                <MenuItem onClick={() => navigate('/blogs')}>Blogs</MenuItem>
                <MenuItem
                  onClick={() => handleNavigation('AppointmentContainer')}
                  sx={{ color: 'hsl(210, 98%, 42%)', textTransform: 'none' }}
                >
                  <strong>Appointments</strong>
                </MenuItem>
                <MenuItem onClick={() => navigate('/about-us')}>About Us</MenuItem>
                <MenuItem onClick={() => navigate('/contact-us')}>Contact Us</MenuItem>
                <MenuItem onClick={() => handleNavigation('faq')}>FAQs</MenuItem>
                <MenuItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    sx={{
                      color: '',
                      textTransform: 'none',
                      borderColor: ' ',
                      '&:hover': {
                        borderColor: '',
                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <Link href="https://calendly.com/sitinovations-jvod/60min" style={{ color: '', textDecoration: 'none' }}>
                      <b>Get Your Growth</b>
                    </Link>
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
