import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import WhatsAppButton from './landing-page/components/whatsappButton';
import LoadingSpinner from './landing-page/components/LoadingSpinner'; // To show while loading components
import Blogs from './landing-page/Pages/Blogs'
import SingleBlog from './landing-page/Pages/SingleBlog'
import AppAppBar from './landing-page/components/AppAppBar';
import Footer from './landing-page/components/Footer';
import ToggleColorMode from './landing-page/components/ToggleColorMode';
import getLPTheme from './landing-page/getLPTheme';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';

// Lazy load all components
const LandingPage = lazy(() => import('./landing-page/LandingPage'));
const TermsOfService = lazy(() => import('./assets/legals/TermsofService'));
const PrivacyPolicy = lazy(() => import('./assets/legals/PrivacyPolicy'));
const AboutUs = lazy(() => import('./landing-page/Pages/AboutUs'));
const ContactUs = lazy(() => import('./landing-page/Pages/ContactUs'));
// const Blogs = lazy(() => import('./landing-page/Pages/Blogs'));
// const SingleBlog = lazy(() => import('./landing-page/Pages/SingleBlog'));
const Page404 = lazy(() => import('./landing-page/Pages/Page404')); // For 404

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
    </Box>
    </>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

function App() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
<Router>
<ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
<CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={ToggleColorMode} />
      <Suspense fallback={<LoadingSpinner message="Loading..." />}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<SingleBlog />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
      <WhatsAppButton />
      <Footer/>
      </ThemeProvider>
    </Router>
  );
}

export default App;
